<template>
<div class="h100">

    <video-player v-show="!showOutro" class="video-player-box"
        ref="videoPlayerIntro"
        :options="playerOptions"
        :playsinline="true"
        @ended="onPlayerEnded($event)"
        customEventName="customstatechangedeventname"
        ></video-player>

    <video-player v-show="showOutro" class="video-player-box"
        ref="videoPlayerOutro"
        :options="playerOptionsOutro"
        :playsinline="true"

        @ended="onPlayerEndedOutro($event)"
        ></video-player>

    <div class="modal" v-show="showPopin">
        <div class="modal-visuel" v-show="showPreviewTel">
            <img src="img/visuel-teleconsultation.jpg" alt="Téléconsultation" />
        </div>
        <!-- AVANT VALIDATION -->
        <div class="modal-content calendrier" v-show="!showPreviewTel">

            <div class="consultation">
                <!-- MEDECIN -->
                <div class="text text1">
                    <h2>Médecin</h2>
                    <p>
                        Je vous passe donc sous biothérapie. <br>
                        Ce ne sera donc plus des comprimés mais des injections IV à venir faire à l'hôpital.
                        La première injection se fera lors de notre prochain RDV, ensuite 2 semaines plus tard,
                        la troisième un mois après la deuxième, puis toutes les 8 semaines.<br>
                        Je vous rassure il comporte très peu d’effets indésirables et c’est un traitement efficace.
                    </p>
                </div>

                <!-- PATIENT -->
                <div class="text text2">
                    <h2>Patient</h2>
                    <p>Très bien, dans ce cas quelles sont les prochaines étapes ?</p>
                </div>
                <!-- MEDECIN -->
                <div class="text text3">
                    <h2>Médecin</h2>
                    <p>
                        Nous allons d’ores et déjà programmer un rendez-vous pour
                        la première injection puis nous ferons des téléconsultations de suivi
                        pour vérifier que tout se passe bien. En parallèle, je programme une
                        perfusion de fer injectable.
                    </p>
                </div>
            </div>


        </div>

        <!-- MODAL FOOTER -->
        <div class="modal-footer">

            <!-- BTN SUIVANT - AFFICHER UNE FOIS QUE LE USER A VALIDE CETTE ETAPE POUR PASSER A LA PROCHAINE -->
            <button v-if="!showPreviewTel" type="button" class="btn btn-blue align-right" @click="goNext()"><span>Suivant</span></button>

            <button v-if="showPreviewTel" type="button" class="btn btn-blue align-center" @click="goOutro()"><span>Accéder à la téléconsultation</span></button>
        </div>
    </div>
</div>



</template>

<script>
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

import { EventBus } from '@/core/event-bus.js';

import { GAME_STEP, GAME_EVENT } from '@/core/gameConfig'
export default {
    data() {
        return {
            playerOptions: {
				// videojs options
				muted: true,
				language: 'fr',
				controls: false,
				//playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [{
					type: "video/mp4",
					src: "introbureau.mp4"
				}],
				//poster: "/static/images/author.jpg",
			},
            playerOptionsOutro: {
				// videojs options
				muted: true,
				language: 'fr',
				controls: false,
				//playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [{
					type: "video/mp4",
					src: "zoomvisio.mp4"
				}],
				//poster: "/static/images/author.jpg",
			},
            showPopin: false,
            showOutro: false,
            showPreviewTel: false
        }

    },
    components : {
		videoPlayer,
    },
    computed: {
      player() {
        return this.$refs.videoPlayerIntro.player
      },
      playerOutro() {
        return this.$refs.videoPlayerOutro.player
      },
    },
    mounted() {
        EventBus.$on('startTransition', () => {
            this.player.play()
		});
    },
    methods: {
        goNext() {
            this.showPreviewTel = true;

            // on pause le chrono
            EventBus.$emit(GAME_EVENT.PAUSECHRONO);
        },
        goOutro() {
            // on relance le chrono
            EventBus.$emit(GAME_EVENT.REPRENDCHRONO);

            this.showPopin = false;
            this.showOutro = true;
            this.playerOutro.play();
            EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.MAISON);
        },
        onPlayerEnded() {
            this.showPopin = true
        },
        onPlayerEndedOutro() {
            this.$store.commit("setGameStep", GAME_STEP.MAISON)
            EventBus.$emit(GAME_EVENT.SHOWDIRECTIVE, '2bis');
            // this.showPopin = true
        }
    }
}
</script>
<style lang="scss" scoped>
.modal{
    display: block;
}
.video-player-box {
    position:absolute;
    top: 0;
    left: 0;
    height: 638px;

    .video-js {
        height: 638px;
    }

    video {
        height: 638px;
    }
}
</style>